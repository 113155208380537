/*
var moreToggle = require('../../../templates/shared/more-links.js');

var options = moreToggle.getOptions(this.$element, 4);
options.lines.tablet = 3;
moreToggle.initialize(options);

https://pultevsts.visualstudio.com/Digital%20Marketing/_wiki/wikis/Digital-Marketing.wiki/4829/More-functionality-(...More-more-links.js)
*/

module.exports = {
    getOptions: getOptions,
    initialize: initialize
};

function initialize(options) {
    if (options && options.jquery) 
        options = getOptions(options);

    if (!options || !options.parentElement || !options.parentElement.jquery) 
        throw new Error("more-links.js: invalid argument to initialize.");
    
    prepareMoreLinks(options);
    setupClickEvent(options.parentElement);
    handleResize(options);
}

function getOptions($element, lines) {
    // default lines to 3 unless lines is passed in
    // mobile and desktop not supported
    return {
        lines: {
            laptop: lines ? lines : 3,
            tablet: lines ? lines : 3
        },
        parentElement: $element
    };
}

// ...More | More/Less Toggle
function prepareMoreLinks(options) {
    var $parentElement = options.parentElement;

    if ($parentElement.find('.expandable').length == 0) return;

    var linesToShow = getLines(options.lines, $(window).width());

    $parentElement.find('.expandable').each(function(index, el) {
        var $element = $(el);

        var truncatedText = '';
        var lineHeight = parseInt($element.css('lineHeight'));
        var maxLinesHeight = Math.floor(lineHeight * (linesToShow + 1));

        var sampleElement = createTestElement($element); 
        if (sampleElement.clientHeight < maxLinesHeight) return finalizeSetup(sampleElement, $element, truncatedText);

        var tempText = '';
        var words = $element.text().split(' ');
        $.each(words, function(index, word) {
            tempText += tempText.length === 0 ? word : (' ' + word);

            //create a sample element that matches what would go on the page to test if we have exceeded the alloweable lines once the ...More button is added to the truncated text
            //the following line is not what is inserted on the page
            sampleElement.innerHTML = '<span class="truncated-text">' + tempText + '</span> ' + '<button class="more-toggle-button">...More</button>'; 

            if (sampleElement.clientHeight >= maxLinesHeight) return false; // break out

            truncatedText = tempText;
        });
       
        $element.nextAll('.more-toggle-button').eq(0).removeClass('hidden');

        finalizeSetup(sampleElement, $element, truncatedText);
    });
}

function setupClickEvent($parentElement) {
    $parentElement.find('.more-toggle-button').on('click', function () {
        var $element = $(this);

        $element.text($element.text() == "See Less" ? "...More" : "See Less");
        $element.parent().toggleClass('collapsed');
    });
}

function handleResize(options) {
    var $toggleButtons = options.parentElement.find('.more-toggle-button');
    var $expandables = options.parentElement.find('.expandable');

    $(window).on('resize', function() {
        $toggleButtons.removeClass('hidden').text("...More");
        $expandables.removeClass('collapse').parent().removeClass('collapsed');

        prepareMoreLinks(options);
    });
}

function getLines(lineOptions, screenWidth) {
    if (screenWidth > 1919) return 15; // all lines

    return screenWidth < 1025 ? lineOptions.tablet : lineOptions.laptop;
}

function createTestElement($element) {
    var parentWidth = $element.parent()[0].offsetWidth;

    var test = document.createElement("span");
    document.body.appendChild(test);

    test.style.font = $element.css('font');
    test.style.lineHeight = $element.css('lineHeight');
    test.style.fontSize = $element.css('fontSize');
    test.style.height = 'auto';
    test.style.width = parentWidth + 'px';
    test.style.position = 'absolute';
    test.innerHTML = $element.text();

    return test;
}

function finalizeSetup(sampleElement, $element, finalTrunc) {
    $element.next('.truncated-text').text(finalTrunc);
    
    document.body.removeChild(sampleElement);

    if (!$element.parent().hasClass('collapsed'))
        setCollapseState($element.parent(), finalTrunc !== '');
}

function setCollapseState($elementParent, isTruncated) {
    if (isTruncated) 
        $elementParent.addClass('collapsed');
    else 
        $elementParent.find('.more-toggle-button').addClass('hidden');
}
