var component = require('../../../lib/js/component.js');
var ResponsiveMedia = require('../responsive-media/responsive-media.js');
var utils = require('../../../lib/js/utils.js');

function ExpandedCarouselV2() {
    var component;
    var lastNavClicked;

    this.initialize = function (element, options) {
        component = this;
        this.activeCarousel;
        this.activeThumbnails;
        this.closeButton = component.find('.carousel-modal-close');
        this.originalCarousel;
        this.focusedIndex = 0;

        // Add event listener to open expanded carousel
        $(document).on('click', '.Carousel-v2 .Carousel', function(event) {
            component.originalCarousel = $(this);

            // Set slide index
            var slideIndex = 0;
            var activeSlide = $(component.originalCarousel).find('.owl-item.active .Carousel-slide');

            if (activeSlide) {
                // Do not open expanded carousel when clicking on the lead form
                if (component.isLeadFormSlide(event)) {
                    return;
                }

                slideIndex = $(activeSlide).data('slide-index');
            }

            component.openCarouselModal(slideIndex);
        });

        // Add event listener to open expanded carousel from supplemental image
        component.handleSupplementalImages();

        // Add event listener to traverse carousel when clicking a thumbnail
        $(document).on('click', '.ExpandedCarousel-v2 .expanded-carousel-thumbnails .Carousel-slide', function(event){
            var slideIndex = $(this).data('slide-index');
            component.setActiveSlide(slideIndex);
            component.setActiveThumbnail(slideIndex);
        });

        // Add event listener to close expanded carousel
        $(document).on('click', '.ExpandedCarousel-v2 .carousel-modal-close', function(event) {
            component.closeModal(component.$element);
        });

        // Gracefully close modal when the screen is below the medium breakpoint (1025px)
        window.addEventListener('resize', function(event){
            var minimumWidth = 1025;
            if (window.innerWidth < minimumWidth && component.$element.hasClass('active')) {
                component.closeModal(component.$element);
                component.removeCarousel();
                component.removeThumbnails();
            }
        });

        // Add keyboard functionality
        component.setKeyboardFunctionality();

        // Add event listener to lead form submit button
        $(document).on('click', '.ExpandedCarousel-v2 .lead-form-submit', function(){    
            var leadFormSubmitButton = $(this);
            
            var leadFormSlide = $(leadFormSubmitButton).closest('.LeadFormSlide')
            if (leadFormSlide) {
                var firstName = $(leadFormSlide).find('input[name="FirstName"]').val();
                var lastName = $(leadFormSlide).find('input[name="LastName"]').val();
                var email = $(leadFormSlide).find('input[name="Email"]').val();
                var zipCode = $(leadFormSlide).find('input[name="ZipCode"]').val();

                $(leadFormSubmitButton).attr("data-first-name", firstName);
                $(leadFormSubmitButton).attr("data-last-name", lastName);
                $(leadFormSubmitButton).attr("data-email", email);
                $(leadFormSubmitButton).attr("data-zipcode", zipCode);
            }
        });
    }

    this.openCarouselModal = function (slideIndex) {
        // Initialize carousel
        var carouselContainer = component.$element.find('.expanded-carousel-slides');
        component.createCarousel(component.originalCarousel, carouselContainer, slideIndex);

        // Initialize thumbnails
        var thumbnailsContainer = component.$element.find('.expanded-carousel-thumbnails');
        component.createThumbnails(component.originalCarousel, thumbnailsContainer, slideIndex);

        // Initialize lead form
        component.initializeLeadForm(carouselContainer);

        component.openModal(component.$element);

        component.setFocusOnCloseButton();
        component.setAriaLabels();
        component.setTabIndex();
        component.setScreenReaderText();

        component.disableSingleImageCarouselSwipe();
    }

    // Carousel
    this.createCarousel = function(originalCarousel, carouselContainer, targetSlide) {
        if (originalCarousel && carouselContainer) {
            var slides = $(originalCarousel).find('.owl-item:not(.cloned) .Carousel-slide').clone();

            $.each($(slides), function(index, slide){
                var horizontalLine = $(slide).find('.Horizontal-line');
                $(horizontalLine).remove();

                var carouselCta = $(slide).find('.Carousel-cta');
                $(carouselCta).remove();
            });

            $(carouselContainer).html(slides);

            this.options = {
                dots: false,
                items: 1,
                loop: true,
                nav: true,
                navElement: 'button',
                navSpeed: 250,
                navText: [
                    '<span class="sr-only">Previous</span><i class="icon-arrow-left"></i>',
                    '<span class="sr-only">Next</span><i class="icon-arrow-right"></i>'
                ],
                onTranslated: component.onCarouselTranslated
            };

            $responsiveImages = component.find('img.u-responsiveMedia');
            $responsiveImages.removeAttr("src");
            $responsiveImages.attach(ResponsiveMedia);
            $responsiveImages.trigger('RESPONSIVE_LAZYLOAD');
            $responsiveImages.css('display', 'block');

            component.activeCarousel = $(carouselContainer).owlCarousel(this.options);

            component.updateControlStyling(component.activeCarousel);

            component.addNavigationEvents(component.activeCarousel);
    
            if (targetSlide) {
                $(carouselContainer).trigger('to.owl.carousel', targetSlide);
            }
        }
    }

    this.onCarouselTranslated = function(event){
        event.preventDefault();

        var activeSlide = $(component.activeCarousel).find('.owl-item.active .Carousel-slide');
        if (activeSlide) {
            var slideIndex = $(activeSlide).data('slide-index');
            component.setActiveThumbnail(slideIndex);
            component.setOriginalCarousel(slideIndex);

            component.setAriaLabels();
            component.setTabIndex();

            // added this logic so that the focus does not jump to another element
            // neccessary because of the way we dynamically set focus to a numbered index
            var numberOfElementsNeededToSkipToStayOnNextNav = 4;
            var numberOfElementsNeededToSkipToStayOnPrevNav = 6;
            if ($(activeSlide).data('type') == 'leadform') {
                if(lastNavClicked == 'next') {
                    component.focusedIndex += numberOfElementsNeededToSkipToStayOnNextNav;
                } else if (lastNavClicked == 'prev') {
                    component.focusedIndex += numberOfElementsNeededToSkipToStayOnPrevNav;
                }
                $(component.selectable).blur();
                component.selectable[component.focusedIndex].focus();
            } else {
                component.setFocusToFocusedIndex();
            }
            
        }
    }

    this.setActiveSlide = function(slideIndex){
        if (slideIndex >= 0) {
            $(component.activeCarousel).trigger('to.owl.carousel', slideIndex);
        }
    }

    this.setOriginalCarousel = function(slideIndex){
        if (slideIndex >= 0) {
            $(component.originalCarousel).trigger('to.owl.carousel', slideIndex);
        }
    }

    this.removeCarousel = function() {
        $(component.activeCarousel).trigger('destroy.owl.carousel');
    }


    // Thumbnails
    this.createThumbnails = function(originalCarousel, thumbnailsContainer, targetThumbnail){
        if (originalCarousel && thumbnailsContainer) {
            var slides = $(originalCarousel).find('.owl-item:not(.cloned) .Carousel-slide').clone();

            // Clean up thumbnail to show only images (remove flag, remove captions, remove social links, remove overlay)
            $(slides).each(function(index, slide){
            	var flag = $(slide).find('.Community-Plan-status-flag');
                $(flag).remove();

                var planFlags = $(slide).find('.Plan-flags');
                $(planFlags).remove();

                var qmiFlags = $(slide).find('.Qmi-flags');
                $(qmiFlags).remove();


                var caption = $(slide).find('.Image-caption');
                $(caption).remove();

                var socialLinks = $(slide).find('.Social-links');
                $(socialLinks).remove();

                var horizontalLine = $(slide).find('.Horizontal-line');
                $(horizontalLine).remove();

                var carouselCta = $(slide).find('.Carousel-cta');
                $(carouselCta).remove();

                var overlay = $(slide).find('.Overlay');
                $(overlay).remove();

            });

            if (targetThumbnail >= 0) {
                $(slides).first('.Carousel-slide[data-slide-index="' + targetThumbnail + '"]')
                    .addClass('active').attr('aria-current', true);
            }

            // Prepare thumbnail carousel settings
            $(thumbnailsContainer).html(slides);

            var numberOfThumbnails = 7;
            this.options = {
                dots: false,
                items: numberOfThumbnails,
                loop: true,
                margin: 10,
                navSpeed: 250
            };

            if(slides.length < numberOfThumbnails) {
                this.options.items = slides.length;
                this.options.loop = false;
                this.options.margin = 0;

                $(thumbnailsContainer).addClass('centered');
            }

            // Initialize thumbnail carousel
            component.activeThumbnails = $(thumbnailsContainer).owlCarousel(this.options);
            $(component.activeThumbnails).find('.Carousel-slide').removeAttr('aria-hidden');

            // Set up thumbnail for lead form if it is enabled
            component.setupLeadFormThumbnail(thumbnailsContainer, numberOfThumbnails, slides);

            if (targetThumbnail) {
                $(thumbnailsContainer).trigger('to.owl.carousel', targetThumbnail);
            }
        }
    }

    this.setActiveThumbnail = function(slideIndex){
        var $activeThumbnails = $(component.activeThumbnails);
        $activeThumbnails.find('.Carousel-slide.active').removeClass('active').removeAttr('aria-current');

        if (slideIndex >= 0) {
            $activeThumbnails.find('.Carousel-slide[data-slide-index="' + slideIndex + '"]')
                .addClass('active').attr('aria-current', true);
            $activeThumbnails.trigger('to.owl.carousel', slideIndex);
        }
    }

    this.removeThumbnails = function () {
        $(component.activeThumbnails).removeClass('centered');
        $(component.activeThumbnails).trigger('destroy.owl.carousel');
    }



    // ADA
    this.setAriaLabels = function(){
        var owlItems = $(component.activeCarousel).find('.owl-item');
        var carouselSlides = $(owlItems).find('.Carousel-slide');
        $(carouselSlides).attr({ 'aria-hidden': 'true' });

        var activeSlide = $(owlItems).filter('.active').find('.Carousel-slide');
        $(activeSlide).removeAttr('aria-hidden');
    }

    this.updateControlStyling = function(carouselElement) {
        var navArrow = $(component.originalCarousel).find('.owl-nav .owl-next');
        var color = $(navArrow).css('border-color');
        var backgroundColor = $(navArrow).css('background-color');        

        var owlNavNext = $(carouselElement).find('.owl-nav .owl-next');
        var owlNavPrev = $(carouselElement).find('.owl-nav .owl-prev');

        $(owlNavNext).css('border-color', color); 
        $(owlNavPrev).css('border-color', color); 
        $(owlNavNext).css('background-color', backgroundColor);
        $(owlNavPrev).css('background-color', backgroundColor);
        $(owlNavNext).find('i').css('color', color); 
        $(owlNavPrev).find('i').css('color', color); 
    }

    this.addNavigationEvents = function(carouselElement) {
        var owlNavNext = $(carouselElement).find('button.owl-next');
        owlNavNext.on('click', function() {
            lastNavClicked = 'next';
        });

        var owlNavPrev = $(carouselElement).find('button.owl-prev');
        owlNavPrev.on('click', function() {
            lastNavClicked = 'prev';
        });
    }

    this.setKeyboardFunctionality = function(){
        var blockModalEscape = false;

        $(document).on("hide.bs.modal", function(event){
            setTimeout(function() {blockModalEscape = false}, 200);
        });

        $(document).on("shown.bs.modal", function(event){
            blockModalEscape = true;
        });

        // for non-screen reader keyboard use, keydown will not capture arrow keys when nvda screen reader is in its default browse mode
        $(document).on("keydown", function(event){

            if ($(component.$element).hasClass('active')) {
                switch (event.originalEvent.key) {
                    case 'ArrowLeft':
                    case 'Left':
                        $(component.activeCarousel).trigger("prev.owl.carousel");
                        break;

                    case 'ArrowRight':
                    case 'Right':
                        $(component.activeCarousel).trigger("next.owl.carousel");
                        break;

                    case 'Escape':
                    case 'Esc':
                        if (!blockModalEscape) // don't close if another modal was just closed - share | account login
                            component.closeModal(component.$element);
                        break;

                    case 'Tab':
                        if (event.shiftKey)
                            component.handleShiftTabPressed(event);
                        else
                            component.handleTabPressed(event);
                        break;
                }
            }
        });
    }

    this.setScreenReaderText = function(){
        var carouselScreenReaderText = "This is a carousel that displays images. " +
        "Users can use the next or previous buttons to move to the next or previous image. " + 
        "While navigating this carousel, it will loop to the first or last image if the user has reached the beginning or end of the carousel. " + 
        "Users can use the like button to save the image to the user's list of liked images if they are logged in. " + 
        "If they are not logged in a modal will appear prompting them to log in or create an account. " + 
        "Users can use the share button to share the current image on social media platforms. " + 
        "Users can use the keyboard keys to interact with the carousel. The left and right arrow keys will navigate through the carousel, the escape key will close the modal, and the enter key simulates a click to the focused element. ";
        
        var carouselScreenReaderSpan = $("<span></span>", { class: 'sr-only' }).text(carouselScreenReaderText);
        component.find('.expanded-carousel-slides').prepend(carouselScreenReaderSpan);

        var thumbnailsScreenReaderText = "This is a carousel that displays thumbnail images for the main carousel in the modal." +
        "The thumbnail images will loop along with the main carousel as different images are displayed.";
        
        var thumbnailsScreenReaderSpan = $("<span></span>", { class: 'sr-only' }).text(thumbnailsScreenReaderText);
        component.find('.expanded-carousel-thumbnails').prepend(thumbnailsScreenReaderSpan);
    }

    this.handleShiftTabPressed = function(event) {
        event.preventDefault();

        var previousTabIndex = component.focusedIndex - 1;
        if (previousTabIndex < 0) {
            previousTabIndex = component.selectable.length - 1;
        }

        $(component.selectable).blur();

        component.selectable[previousTabIndex].focus();
        component.focusedIndex = previousTabIndex;
    }

    this.handleTabPressed = function(event) {
        event.preventDefault();

        var nextTabIndex = component.focusedIndex + 1;
        if (nextTabIndex > component.selectable.length - 1) {
            nextTabIndex = 0;
        }

        $(component.selectable).blur();
        
        component.selectable[nextTabIndex].focus();
        component.focusedIndex = nextTabIndex;
    }

    this.setFocusOnCloseButton = function(){
        setTimeout(function() {
            component.closeButton.focus();
            component.focusedIndex = 0;
        }, 350); 
        
        // A timeout is used because focus cannot be set on an element that is not visible yet
        // The timeout is 350ms because the animation to display the modal is 0.33s
    }

    this.setFocusToFocusedIndex = function(){
        $(component.selectable).blur();
        component.selectable[component.focusedIndex].focus();
    }

    this.setTabIndex = function(){
        component.find('.owl-prev, .owl-item a, .owl-next').attr('tabindex', -1);

        component.selectable = [];

        component.selectable.push(component.find('.carousel-modal-close')[0]);

        var leadForm = component.find('.owl-item.active .Carousel-slide__lead-form');
        if (leadForm && leadForm.length) {
            var inputs = leadForm.find('input, .submit-button');
            $.each(inputs, function(index, input){
                component.selectable.push(input);
            });
        }

        component.selectable.push(component.find('.owl-prev')[0]);

        var socialItems = component.find('.owl-item.active a:not(.submit-button)');
        $.each(socialItems, function(index, item){
            component.selectable.push(item);
        });
    }

    // ADA
    this.setAriaLabels = function(){
        var owlItems = $(component.activeCarousel).find('.owl-item');
        var carouselSlides = $(owlItems).find('.Carousel-slide');
        $(carouselSlides).attr({ 'aria-hidden': 'true' });

        var activeSlide = $(owlItems).filter('.active').find('.Carousel-slide');
        $(activeSlide).removeAttr('aria-hidden');
    }

    this.updateControlStyling = function(carouselElement) {
        var navArrow = $(component.originalCarousel).find('.owl-nav .owl-next');
        var color = $(navArrow).css('border-color');
        var backgroundColor = $(navArrow).css('background-color');        

        var owlNavNext = $(carouselElement).find('.owl-nav .owl-next');
        var owlNavPrev = $(carouselElement).find('.owl-nav .owl-prev');

        $(owlNavNext).css('border-color', color); 
        $(owlNavPrev).css('border-color', color); 
        $(owlNavNext).css('background-color', backgroundColor);
        $(owlNavPrev).css('background-color', backgroundColor);
        $(owlNavNext).find('i').css('color', color); 
        $(owlNavPrev).find('i').css('color', color); 
    }

    this.setKeyboardFunctionality = function(){
        $(document).on("keydown", function(event){
            if ($(component.$element).hasClass('active') && (event.originalEvent.key == 'ArrowLeft' || event.originalEvent.key == 'Left')) {
                $(component.activeCarousel).trigger("prev.owl.carousel");
            }

            if ($(component.$element).hasClass('active') && (event.originalEvent.key == 'ArrowRight' || event.originalEvent.key == 'Right')) {
                $(component.activeCarousel).trigger("next.owl.carousel");
            }

            if ($(component.$element).hasClass('active') && (event.originalEvent.key == 'Escape' || event.originalEvent.key == 'Esc')) {
                component.closeModal(component.$element);
            }

            if ($(component.$element).hasClass('active') && event.shiftKey && event.originalEvent.key == 'Tab') {
                component.handleShiftTabPressed(event);
            }
            else if ($(component.$element).hasClass('active') && event.originalEvent.key == 'Tab') {
                component.handleTabPressed(event);
            }
        });
    }

    this.setScreenReaderText = function(){
        var carouselScreenReaderText = "This is a carousel that displays images. " +
        "Users can use the next or previous buttons to move to the next or previous image. " + 
        "While navigating this carousel, it will loop to the first or last image if the user has reached the beginning or end of the carousel. " + 
        "Users can use the like button to save the image to the user's list of liked images if they are logged in. " + 
        "If they are not logged in a modal will appear prompting them to log in or create an account. " + 
        "Users can use the share button to share the current image on social media platforms. " + 
        "Users can use the keyboard keys to interact with the carousel. The left and right arrow keys will navigate through the carousel, the escape key will close the modal, and the enter key simulates a click to the focused element. ";
        
        var carouselScreenReaderSpan = $("<span></span>", { class: 'sr-only' }).text(carouselScreenReaderText);
        component.find('.expanded-carousel-slides').prepend(carouselScreenReaderSpan);

        var thumbnailsScreenReaderText = "This is a carousel that displays thumbnail images for the main carousel in the modal." +
        "The thumbnail images will loop along with the main carousel as different images are displayed.";
        
        var thumbnailsScreenReaderSpan = $("<span></span>", { class: 'sr-only' }).text(thumbnailsScreenReaderText);
        component.find('.expanded-carousel-thumbnails').prepend(thumbnailsScreenReaderSpan);
    }

    this.handleShiftTabPressed = function(event) {
        event.preventDefault();

        var previousTabIndex = component.focusedIndex - 1;
        if (previousTabIndex < 0) {
            previousTabIndex = component.selectable.length - 1;
        }

        $(component.selectable).blur();

        component.selectable[previousTabIndex].focus();
        component.focusedIndex = previousTabIndex;
    }

    this.handleTabPressed = function(event) {
        event.preventDefault();

        var nextTabIndex = component.focusedIndex + 1;
        if (nextTabIndex > component.selectable.length - 1) {
            nextTabIndex = 0;
        }

        $(component.selectable).blur();
        
        component.selectable[nextTabIndex].focus();
        component.focusedIndex = nextTabIndex;
    }

    this.setFocusOnCloseButton = function(){
        setTimeout(function() {
            component.closeButton.focus();
            component.focusedIndex = 0;
        }, 350); 
        
        // A timeout is used because focus cannot be set on an element that is not visible yet
        // The timeout is 350ms because the animation to display the modal is 0.33s
    }

    this.setFocusToFocusedIndex = function(){
        $(component.selectable).blur();
        component.selectable[component.focusedIndex].focus();
    }

    this.setTabIndex = function(){
        component.find('.owl-prev, .owl-item a, .owl-next').attr('tabindex', -1);

        component.selectable = [];

        component.selectable.push(component.find('.carousel-modal-close')[0]);

        var leadForm = component.find('.owl-item.active .Carousel-slide__lead-form');
        if (leadForm && leadForm.length) {
            var inputs = leadForm.find('input, .submit-button');
            $.each(inputs, function(index, input){
                component.selectable.push(input);
            });

            component.selectable.push(component.find('.owl-prev')[0]);
        }
        else {
            component.selectable.push(component.find('.owl-prev')[0]);
        }

        var socialItems = component.find('.owl-item.active a:not(.submit-button)');
        $.each(socialItems, function(index, item){
            component.selectable.push(item);
        });

        component.selectable.push(component.find('.owl-next')[0]);

        $.each(component.selectable, function(index, item){
            $(item).attr('tabindex', 0);
        });
    }

    // Lead Form
    this.isLeadFormSlide = function(event){
        if (event.target && (event.target.className.indexOf('row') != -1 || 
                             event.target.className.indexOf('lead-form-slide') != -1 || 
                             event.target.className.indexOf('lead-form-submit') != -1 || 
                             event.target.className.indexOf('TooltipBtn--info') != -1)) {
            return true;
        }

        return false;
    }

    this.initializeLeadForm = function(carousel){
        var phoneInputs = $(carousel).find('input[name="Phone"]');
        if(phoneInputs && phoneInputs.length){
            for (var index = 0; index < phoneInputs.length; index++) {
                var phoneInput = phoneInputs[index];

                $(phoneInput).change(this.formatPhoneNumber.bind(this));
            }
        }

        // Initialize KO bindings
        var leadFormSlides = $(carousel).find('.LeadFormSlide');
        if (leadFormSlides) {
            var koViewModel = new LeadFormViewModel();

            for (var windex = 0; windex < leadFormSlides.length; windex++) {
                var leadFormSlide = leadFormSlides[windex];
                ko.applyBindings(koViewModel, leadFormSlide);      
            }
        }
    }

    this.setupLeadFormThumbnail = function(thumbnailsContainer, numberOfThumbnails, slides){
        var formThumbnails = $(thumbnailsContainer).find('.owl-item .Carousel-slide__lead-form');
        if (formThumbnails && formThumbnails.length) {
            var referenceThumbnail = $(thumbnailsContainer).find('.owl-item .Carousel-slide').first();
            if (referenceThumbnail) {
                var thumbnailHeight = $(referenceThumbnail).outerHeight();

                $.each($(formThumbnails), function(index, thumbnail){
                    var parent = $(thumbnail).parent('.owl-item');
                    $(parent).css('height', thumbnailHeight + 'px');
    
                    if (slides.length < numberOfThumbnails) {
                        var thumbnailWidth = $(referenceThumbnail).outerWidth();
                        $(thumbnail).css('width', thumbnailWidth + 'px');                            
                    }
    
                    var formLabel = $('<div class="Thumbnail-Form-Label"></div>').text('Learn more');
                    $(thumbnail).html(formLabel);
                });   
            }
        }
    }


    // Helpers
    this.openModal = function(element) {
        $(element).addClass("active");
    }

    this.closeModal = function(element) {
        $(element).removeClass("active");
        
        component.removeCarousel();
        component.removeThumbnails();
        
        $(component.originalCarousel).find('button.image-wrapper[aria-hidden="false"]').trigger('focus');
        $(component.originalCarousel).trigger("refresh.owl.carousel");
    }

    this.formatPhoneNumber = function(event){
        var phoneInput = event.target;
        if (phoneInput) {
            $(phoneInput).val(utils.formatDirtyPhoneNumber($(phoneInput).val()));
        }
    }

    var LeadFormViewModel = function() {
        var defaultFirstName = "";
        var defaultLastName = "";
        var defaultEmail = "";
        var defaultZipcode = "";
        var defaultPhone = "";

        // set default values if user info is populated
        if (Pulte && Pulte.user) {
            defaultFirstName = Pulte.user.FirstName;
            defaultLastName = Pulte.user.LastName;
            defaultEmail = Pulte.user.Email;
            defaultZipcode = Pulte.user.ZipCode;
            defaultPhone = Pulte.user.Phone;
        }

        this.firstName = ko.observable(defaultFirstName);
        this.lastName = ko.observable(defaultLastName);
        this.email = ko.observable(defaultEmail);
        this.zipcode = ko.observable(defaultZipcode);
        this.phone = ko.observable(defaultPhone);
    };

    this.handleSupplementalImages = function() {
        $(document).on('click', '[data-click-modal-supplemental-images]', function(event) {
            var moduleName = event.currentTarget.getAttribute('data-click-modal-supplemental-images');
            component.originalCarousel = $('[data-carousel-supplemental-images="' + moduleName + '"]');
            var slideIndex = event.currentTarget.getAttribute('index');
            if (slideIndex) 
                component.openCarouselModal(slideIndex);
        });
    }; 

    this.disableSingleImageCarouselSwipe = function () {
        // Single Image Carousels (Expanded) have 6 cloned items
        var isSingleImageCarousel = component.find('.owl-item').length === 6;
        if (isSingleImageCarousel) {
            component.find('.owl-stage').css({'pointer-events': 'none', 'cursor': 'pointer'});
            component.find('.Social-btn, .Share-icon').css('pointer-events', 'all');
        }
    }
}

module.exports = component(ExpandedCarouselV2);
